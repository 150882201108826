var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"bg-login",attrs:{"id":"auth"}},[_c('v-main',[_c('v-container',{staticClass:"login-container",attrs:{"fluid":""}},[_c('v-row',{staticClass:"login-container__row",attrs:{"align-content":"center"}},[_c('v-col',{staticClass:"login-container__col left hidden-sm-and-down",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"left__text"},[_c('p',{staticClass:"uppercase"},[_vm._v("Conocimiento")]),_c('p',{staticClass:"uppercase"},[_vm._v("Y Confiabilidad")])])]),_c('v-col',{staticClass:"login-container__col right",attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8","offset":"2"}},[_c('div',{staticClass:"logo-container d-flex flex-column justify-center align-center"},[_c('img',{staticClass:"logo-container__image",attrs:{"src":require("../../assets/AV_002_logo.jpg"),"alt":"Logo Av"}})])]),_c('v-col',{attrs:{"cols":"10","offset":"1"}},[(_vm.passwordReset === false)?_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-text',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('v-row',{staticClass:"px-md-5"},[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Correo electrónico","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Correo electrónico","hint":"Introduzca su correo electrónico","error-messages":errors[0]},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Contraseña Nueva","rules":"required|min:8|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"password","label":"Contraseña Nueva*","hint":"Ingrese la contraseña","type":"password","error-messages":errors[0]},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Confirmar Contraseña","rules":"required|min:8","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Confirmar Contraseña*","hint":"Ingrese nuevamente la contraseña","type":"password","error-messages":errors[0]},model:{value:(_vm.user.password_confirmation),callback:function ($$v) {_vm.$set(_vm.user, "password_confirmation", $$v)},expression:"user.password_confirmation"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"login-button",attrs:{"color":"primary","type":"submit","loading":_vm.loading,"disabled":invalid}},[_vm._v("Cambiar contraseña")])],1)],1)]}}],null,false,1166368750)})],1)],1):_c('v-card',{staticClass:"pa-6",attrs:{"rounded":"","elevation":"0"}},[_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('span',{staticClass:"logo-container__text"},[_vm._v(" Cambio de contraseña exitoso ")])]),_c('v-row',{attrs:{"justify":"center pt-2"}},[_c('RouterLink',{staticClass:"btn-default",attrs:{"to":{ name: 'Login' }}},[_c('span',{attrs:{"justify":"center"}},[_vm._v("Iniciar Sesión")])])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }